<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Transazioni Di Cassa Incassati"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <v-btn
        icon
        @click="toggleBarcode"
        :color="!!ghost ? 'red' : ''"
      >
        <v-icon>mdi-shield-account-variant</v-icon>
      </v-btn>
      <StandardMenu>
        <template v-slot:activator>
          <v-btn text link @click="goToNew" :disabled="isLicenseBlocked">
            <v-icon class="mr-2">mdi-plus</v-icon> Nuovo Storno
          </v-btn>
        </template>
        <v-list style="min-width: 180px"> </v-list>
      </StandardMenu>

      <ExportExcelTable
        :worksheetName="worksheetName"
        :fileName="fileName"
        :fieldsToExport="fieldsToExport"
        :fetch="fetchExcel"
      > </ExportExcelTable>
    </template>
    <template v-slot:toolbar-extension> </template>
    <template v-slot:content>
      <div class="d-flex justify-left align-left mb-3" style="width: 100%; height: 15%;">
        <AdvancedFilter
          style="width:99%; height: 100%"
          v-model="filtersValue"
          :filters="filterTypes"
          :advance-filters="advanceFilters"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-customer="{filter}">
            Nome: {{ filter.value}}
          </template>

          <template v-slot:custom-paymentType="{filter}">
            <v-row class="pa-2">
              <v-col>
                <v-checkbox v-model="filter.value['cashPaid']" label="Contanti"></v-checkbox>
                <v-checkbox v-model="filter.value['cardPaid']" label="Elettronico"></v-checkbox>
                <v-checkbox v-model="filter.value['checkPaid']" label="Assegno"></v-checkbox>
                <v-checkbox v-model="filter.value['customerCardPaid']" label="Tessera"></v-checkbox>
                <v-checkbox v-model="filter.value['otherPaid']" label="Altro"></v-checkbox>
              </v-col>
            </v-row>
          </template>
          <template v-slot:chip-custom-paymentType="{filter}">
            {{ filter.value['cashPaid'] ? 'Contanti' : '' }}
            {{ filter.value['cardPaid'] ? 'Elettronico' : '' }}
            {{ filter.value['checkPaid'] ? 'Assegno' : '' }}
            {{ filter.value['customerCardPaid'] ? 'Tessera' : '' }}
            {{ filter.value['otherPaid'] ? 'Altro' : '' }}
          </template>
          <template v-slot:custom-transactionType="{filter}">
            <v-row class="pa-2">
              <v-col>
                <v-checkbox v-model="filter.value['paymentCredit']" label="Saldo Debito"></v-checkbox>
                <v-checkbox v-model="filter.value['customerCards']" label="Ricarica Tessera"></v-checkbox>
                <v-checkbox v-model="filter.value['billPayment']" label="Scontrino"></v-checkbox>
                <v-checkbox v-model="filter.value['paymentDebit']" label="Storno"></v-checkbox>
                <v-checkbox v-model="filter.value['promo']" label="Promozione"></v-checkbox>
              </v-col>
            </v-row>
          </template>
          <template v-slot:custom-service="{filter}">
            <v-row class="pa-2" style="width:100%">
              <v-col>
                <ServiceSelector
                  style="width: 100%"
                  mandatory
                  @click="(service) => { filter.value = service }"
                  show-frequent-services
                ></ServiceSelector>
              </v-col>
            </v-row>
          </template>
          <template v-slot:chip-custom-service="{filter}">
            {{ !!filter.value ? filter.value.name : '' }}
          </template>

          <template v-slot:custom-item="{filter}">
            <v-row class="pa-2 py-4">
              <v-col>
                <ItemsAutocomplete
                  v-model="filter.value"
                  :filled="false"
                  :multiple="false"
                  return-object
                  dense
                ></ItemsAutocomplete>
              </v-col>
            </v-row>
          </template>
          <template v-slot:chip-custom-item="{filter}">
            {{ !!filter.value ? filter.value.description : '' }}
          </template>

          <template v-slot:custom-itemPresence="{filter}">
            <v-row class="pa-2 px-6">
              <v-col>
                <v-switch
                  v-model="filter.value"
                  label="Solo Transazioni con Prodotti"
                >
                </v-switch>
              </v-col>
            </v-row>
          </template>
          <template v-slot:chip-custom-itemPresence="{filter}">
            {{ filter.value ? 'Solo Transazioni con Prodotti' : '' }}
          </template>

          <template v-slot:custom-bar="{filter}">
            <v-col>
              <v-switch v-model="filter.value" :label="filter.label"></v-switch>
            </v-col>
          </template>

          <template v-slot:custom-operator="{filter}">
            <v-row class="pa-2 py-4">
              <v-col>
                <OperatorsAutocomplete
                  v-model="filter.value"
                  :filled="false"
                  :multiple="false"
                  return-object
                  dense
                ></OperatorsAutocomplete>
              </v-col>
            </v-row>
          </template>
          <template v-slot:chip-custom-operator="{filter}">
            {{ !!filter.value ? filter.value.lastname : '' }}
          </template>

          <template v-slot:custom-barService="{filter}">
            <v-row class="pa-2" style="width:100%">
              <v-col>
                <Hierarchy
                  :hierarchy="menus"
                  context="menu"
                  item-title="name"
                  item-children="menuTypes"
                  style="max-height: 100%; height: 90%;"
                  @final="(item) => { filter.value = item }"
                  class="mt-2"
                >
                  <template v-slot:final-action="{ item }">
                    <span class="text-overline" v-if="!!item.price">€ {{item.price}}</span>
                    <span class="text-overline" v-else-if="!!item.priceSelling">€ {{item.priceSelling}}</span>
                  </template>
                </Hierarchy>
              </v-col>
            </v-row>
          </template>
          <template v-slot:chip-custom-barService="{filter}">
            Servizio Bar: {{ !!filter.value ? filter.value.name : '' }}
          </template>

          <template v-slot:custom-documentNumber="{filter}">
            <v-row class="pa-2 py-4">
              <v-col>
                <v-text-field
                  v-model="filter.value"
                  dense
                  placeholder="Numero Scontrino"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>

          <template v-slot:chip-custom-documentNumber="{filter}">
            Numero Scontrino contiene {{ filter.value }}
          </template>

          <template v-slot:custom-cashDeskOperator="{filter}">
            <OperatorsAutocomplete
              class="pb-5"
              v-model="filter.value"
              :filled="false"
              :multiple="false"
              return-object
              dense
            ></OperatorsAutocomplete>
          </template>

          <template v-slot:chip-custom-cashDeskOperator="{filter}">
            Operatore Cassa: {{ filter.value ? filter.value.firstname : '' }} {{ filter.value ? filter.value.lastname : '' }}
          </template>
        </AdvancedFilter>
      </div>
      <div style="height: 70%" ref="dataTableWrapper" class="mb-10">
        <TypeDataTable
          v-model="selected"
          loading-text="Caricamento Transazioni Di Cassa Incassati ..."
          item-key="uniqueId"
          :headers="headers"
          :items="paymentsCollected"
          :loading="loading"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :show-actions="false"
          :height="dataTableHeight"
          :show-select="false"
          @input="rowClicked($event)"
          :rowPerPageOptions="[200, 100, 50, 20]"
        >
          <template v-slot:custom-typeTransaction="{ item }">
            <v-chip v-if="item.typeTransaction == 'paymentCredit'" color="yellow"
              >Saldo Debito</v-chip
            >
            <v-chip
              v-else-if="item.typeTransaction == 'customerCards' && !!item.info && !item.info.fromDebit"
              color="green"
              >Ricarica Tessera</v-chip
            >
            <v-chip
              v-else-if="item.typeTransaction == 'customerCards' && !!item.info && !!item.info.fromDebit"
              color="orange"
              >Ricarica Tessera Da Storno</v-chip
            >
            <v-chip
              v-else-if="item.typeTransaction == 'billPayment'"
              color="green"
              >Scontrino</v-chip
            >
            <v-chip
              v-else-if="item.typeTransaction == 'paymentDebit'"
              color="red"
              >Storno - {{ Number(item.cashPaid).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) }}</v-chip
            >
            <v-chip
              v-else-if="item.typeTransaction == 'promo'"
              color="green"
              >Promozione</v-chip>
            <v-chip
              v-else-if="item.typeTransaction == 'voucher'"
              color="orange"
              >Voucher da Storno</v-chip>
          </template>
          <template v-slot:custom-customer="{ item }">
            <v-chip
              v-if="item.typeTransaction == 'promo'"
            >{{getCustomerFromGift(item)}}</v-chip>
            <v-chip v-else>{{item.customer}}</v-chip>
          </template>
          <template v-slot:custom-documentNumber="{ item }">
            <v-chip v-if="!!item.info && item.info.documentNumber" color="#60b0f8">{{item.info.documentNumber}}</v-chip>
            <v-chip v-else color="#60b0f8">Non Presente</v-chip>
          </template>
          <template v-slot:custom-checkPaid="{ item }">
            <v-chip v-if="!!item.checkPaid && Number(item.checkPaid) > 0" color="#f39808">{{ Number(item.checkPaid).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</v-chip>
            <v-chip v-else>{{'0 €'}}</v-chip>
          </template>
          <template v-slot:custom-cashPaid="{ item }">
            <v-chip v-if="!!item.cashPaid && Number(item.cashPaid) > 0 && item.typeTransaction != 'paymentDebit'" color="#f39808">{{ Number(item.cashPaid).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</v-chip>
            <v-chip v-else>{{'0 €'}}</v-chip>
          </template>
          <template v-slot:custom-cardPaid="{ item }">
            <v-chip v-if="!!item.cardPaid && Number(item.cardPaid) > 0" color="#f39808">{{ Number(item.cardPaid).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</v-chip>
            <v-chip v-else>{{'0 €'}}</v-chip>
          </template>
          <template v-slot:custom-otherPaid="{ item }">
            <v-chip v-if="!!item.otherPaid && Number(item.otherPaid) > 0" color="#f39808">{{ Number(item.otherPaid).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</v-chip>
            <v-chip v-else>{{'0 €'}}</v-chip>
          </template>
          <template v-slot:custom-ghostPaid="{ item }">
            <v-chip v-if="!!item.ghostPaid && Number(item.ghostPaid) > 0" color="#f39808">{{ Number(item.ghostPaid).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</v-chip>
            <v-chip v-else>{{'0 €'}}</v-chip>
          </template>
          <template v-slot:custom-customerCardPaid="{ item }">
            <v-chip v-if="!!item.customerCardPaid && Number(item.customerCardPaid) > 0" color="#f39808">{{ Number(item.customerCardPaid).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</v-chip>
            <v-chip v-else>{{'0 €'}}</v-chip>
          </template>

          <template v-slot:custom-items="{ item }">
            <div v-if="!!item.items && item.items.length > 0">
              <ul>
                <li v-for="value in item.items" :key="value.id">
                  {{ value.quantity + ' ' + (value.description || value.name) + ': ' + (!!value.realPrice || value.realPrice == 0 ? value.realPrice : (value.priceSelling || value.price)) + '€'
                   + (!!value.operators && value.operators.length > 0  ? ' - ' + value.operators.map(operator => ( operator.firstname + ' ' + operator.lastname)).join(', ') : '') }}
                </li>
              </ul>
            </div>
            <div v-else-if="item.typeTransaction == 'promo'">
              <v-chip color="grey">{{item.customer}}</v-chip>
            </div>
            <div v-else>
              <span>Non Presenti</span>
            </div>
          </template>
          <template v-slot:custom-total="{ item }">
            <v-chip color="#8fce00">{{Number(item.total).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</v-chip>
          </template>
          <template v-slot:custom-transactionId="{ item }">
            <v-chip color="#8fce00">{{ item.id }}</v-chip>
          </template>
          <template v-slot:custom-services="{ item }">
            <div v-if="!!item.services && item.services.length > 0">
              <ul>
                <li v-for="value in item.services" :key="value.id">
                  {{ value.quantity + ' ' + value.name + ': ' + (!!value.realPrice || value.realPrice == 0 ? value.realPrice : value.price) + '€' 
                  + (!!value.operators && value.operators.length > 0  ? ' - ' + value.operators.map(operator => ( operator.firstname + ' ' + operator.lastname)).join(', ') : '') }}
                </li>
              </ul>
            </div>
            <div v-else>
              <span>Non Presenti</span>
            </div>
          </template>
          <template v-slot:custom-cashDeskOperatorName="{ item }">
            {{item.cashDeskOperatorName}}
          </template>
        
          <template v-slot:externalFooter>
            <TypeDataTable
              :headers="totalHeader"
              :items="totalRow"
              :show-select="false"
              :show-actions="false"
              disablePagination
            >
              <template v-slot:custom-typeTransaction="{ item }">
                <v-chip color="primary">
                  Totale
                </v-chip>
              </template>
              <template v-slot:custom-customer="{ item }">
                <v-chip>{{item.customer}}</v-chip>
              </template>
              <template v-slot:custom-documentNumber="{ item }">
                <v-chip color="#60b0f8">Totale</v-chip>
              </template>
              <template v-slot:custom-checkPaid="{ item }">
                <v-chip v-if="!!item.checkPaid && Number(item.checkPaid) > 0" color="#f39808">{{Number(item.checkPaid).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</v-chip>
                <v-chip v-else>{{'0 €'}}</v-chip>
              </template>
              <template v-slot:custom-cashPaid="{ item }">
                <v-chip v-if="!!item.cashPaid && Number(item.cashPaid) > 0" color="#f39808">{{Number(item.cashPaid).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</v-chip>
                <v-chip v-else>{{'0 €'}}</v-chip>
              </template>
              <template v-slot:custom-cardPaid="{ item }">
                <v-chip v-if="!!item.cardPaid && Number(item.cardPaid) > 0" color="#f39808">{{Number(item.cardPaid).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</v-chip>
                <v-chip v-else>{{'0 €'}}</v-chip>
              </template>
              <template v-slot:custom-otherPaid="{ item }">
                <v-chip v-if="!!item.otherPaid && Number(item.otherPaid) > 0" color="#f39808">{{Number(item.otherPaid).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</v-chip>
                <v-chip v-else>{{'0 €'}}</v-chip>
              </template>
              <template v-slot:custom-ghostPaid="{ item }">
                <v-chip v-if="!!item.ghostPaid && Number(item.ghostPaid) > 0" color="#f39808">{{Number(item.ghostPaid).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</v-chip>
                <v-chip v-else>{{'0 €'}}</v-chip>
              </template>
              <template v-slot:custom-customerCardPaid="{ item }">
                <v-chip v-if="!!item.customerCardPaid && Number(item.customerCardPaid) > 0" color="#f39808">{{Number(item.customerCardPaid).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</v-chip>
                <v-chip v-else>{{'0 €'}}</v-chip>
              </template>
              <template v-slot:custom-total="{ item }">
                <v-chip color="#8fce00">{{Number(item.total).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</v-chip>
              </template>
              <template v-slot:custom-paymentDebit="{ item }">
                <v-chip v-if="!!item.paymentDebit && Number(item.paymentDebit) < 0" color="#f39808">{{Number(item.paymentDebit).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</v-chip>
                <v-chip v-else>{{'0 €'}}</v-chip>
              </template>
            </TypeDataTable>
          </template>
        </TypeDataTable>
        <PaymentTransactionDetailDialog
          v-model="showTransactionDetailDialog"
          :rowSelected="rowSelected"
          @reload-payments="fetchPaymentsCollected()"
          @close="showTransactionDetailDialog = false"
        ></PaymentTransactionDetailDialog>
      </div>
      <StandardDialog
        v-model="dialogBarcode"
        title="Area Riservata Sviluppatori" 
        :dialog-height="null"
        dialog-max-width="500px"
      >
        <ManualBarcodeInput
          v-model="ghostBarcode"
          scan-your-barcode-message="Immettere Codice" 
          @confirm="activeGhost"
        >
        </ManualBarcodeInput>
      </StandardDialog>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import StandardMenu from "@/components/common/StandardMenu.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import paymentCollectedService from "@/services/paymentCollected/paymentCollected.service.js";
import PaymentTransactionDetailDialog from "./PaymentTransactionDetailDialog.vue";
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import operatorService from '@/services/operators/operators.service.js'
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import ServiceSelector from '@/components/common/ServiceSelector.vue';
import StandardDialog from '@/components/common/StandardDialog.vue';
import ItemsAutocomplete from "@/components/common/ItemsAutocomplete.vue";
import OperatorsAutocomplete from "@/components/common/OperatorsAutocomplete.vue";
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import Hierarchy from "@/components/common/Hierarchy.vue";
import dateUtils from "@/mixins/common/dateUtils"
import menuService from "@/services/bar/menu.service.js";
import cashDeskService from "@/services/cashDesks/cashDesks.service.js";

export default {
  name: "PaymentTransactionsCollectedList",
  components: {
    FullScreenDialog,
    TypeDataTable,
    StandardMenu,
    PaymentTransactionDetailDialog,
    AdvancedFilter,
    ManualBarcodeInput,
    StandardDialog,
    ServiceSelector,
    ItemsAutocomplete,
    OperatorsAutocomplete,
    ExportExcelTable,
    Hierarchy
  },
  data: function () {
    let props = this.$router.resolve({ name: "Payments" });

    return {
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      paymentsCollected: [],
      totalRow: undefined,
      headers: [],
      totalHeader: [],

      page: 1,
      rowPerPage: 200,
      totalPages: 0,
      resizeListener: undefined,
      rowSelected: undefined,
      showTransactionDetailDialog: false,
      ghost: false,
      ghostBarcode: undefined,
      dialogBarcode: false,
      filterTypes: [
        { type: 'date', operator: 'between', field: 'date', name: 'Data', label: ['Da', 'A'], color: "", value: [undefined, undefined] },
        { type: 'custom', operator: 'custom', field: 'paymentType', name: 'Tipo Pagamento', label: 'Tipo Pagamento', color: "", value: {}, icon: "mdi-account-cash", blank: {} },
        { type: 'custom', operator: 'custom', field: 'transactionType', name: 'Tipo Transazione', label: 'Tipo Transazione', color: "", value: {}, icon: "mdi-bank-transfer", blank: {} },
        { type: 'cash', operator: 'equal', field: 'total', name: 'Totale', label: 'Totale', color: "", value: undefined },
        { type: 'custom', operator: 'custom', field: 'documentNumber', name: 'Numero Scontrino', label: 'Numero Scontrino', color: "", value: undefined, icon: "mdi-receipt" },
        { type: 'custom', operator: 'custom', field: 'cashDeskOperator', name: 'Operatore Cassa', label: 'Operatore Cassa', color: "", value: undefined, icon: "mdi-cash-register"}
      ],
      advanceFilters: [
        { type: 'cash', operator: 'equal', field: 'cashPaid', name: 'Contanti', label: 'Contanti', color: "", value: undefined },
        { type: 'cash', operator: 'equal', field: 'cardPaid', name: 'Pagamento Elettronico', label: 'Elettronico', color: "", value: undefined },
        { type: 'cash', operator: 'equal', field: 'checkPaid', name: 'Assegno', label: 'Assegno', color: "", value: undefined },
        { type: 'cash', operator: 'equal', field: 'customerCardPaid', name: 'Tessera', label: 'Tessera', color: "", value: undefined },
        { type: 'cash', operator: 'equal', field: 'otherPaid', name: 'Altro Metodo', label: 'Altri Metodi', color: "", value: undefined },
        { type: 'custom', operator: 'custom', field: 'service', name: 'Servizio Salone', label: 'Servizio Salone', color: "", value: {}, icon: "mdi-room-service", blank: {} },
        { type: 'custom', operator: 'custom', field: 'item', name: 'Prodotto', label: 'Prodotto', color: "", value: {}, icon: "mdi-spray-bottle", blank: {} },
        { type: 'custom', operator: 'custom', field: 'operator', name: 'Operatore', label: 'Operatore', color: "", value: {}, icon: "mdi-account", blank: {} },
        { type: 'custom', operator: 'custom', field: 'itemPresence', name: 'Prodotti Presenti', label: 'Prodotti Presenti', color: "", value: undefined, icon: "mdi-spray-bottle" },
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'customer', name: 'Nome', label: 'Nome', color: "", value: undefined },
      filtersValue: [
        { type: 'date', name: 'Data', field: 'date', operator: 'equal', value: this.today() },
      ],
      barFilters: [
        { type: 'custom', operator: 'custom', field:'barService', name: 'Servizio Bar', label: 'Servizio Bar', color: "", value: undefined, icon: "mdi-coffee-maker" },
        { type: 'custom', operator: 'custom', field: 'bar', name: 'Bar', label: 'Bar', color: "", value: false, icon: "mdi-coffee" },
      ],
      worksheetName: "",
      fileName:"",
      fieldsToExport: {},
      currentUser: {},
      viewBar: undefined,
      menus: [],
      isLicenseBlocked: undefined
    };

  },
  mixins:[dateUtils],
  mounted: function () {
    this.fetchHeaders();
    if(!!this.viewBar.active){
      this.advanceFilters.push(...this.barFilters);
      this.fetchMenus();
    }

    this.fetchPaymentsCollected();

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight *0.85;

    this.resizeListener = function () {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight *0.85;
    };
    this.resizeListener = this.resizeListener.bind(this);
    window.addEventListener("resize", this.resizeListener);

    this.fieldsToExport = paymentCollectedService._fieldsToExport(this.ghost);
    this.worksheetName = "Export Transazioni ";
    this.fileName =
      "TransazioniDiCassa-Incassati_" + this._buildDateToExport(new Date()) +
      ".xls";

  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.resizeListener);

  },
  methods: {
    today(){
      let tmpDate = new Date();
      tmpDate.setHours(0, 0, 0, 0);
      return tmpDate
    },
    fetchPaymentsCollected() {
      let filters = [...this.filtersValue, { type: 'custom', operator: 'custom', field: 'ghost', value: this.ghost }];
      this.loadingItems = true;
      paymentCollectedService
        .list(this.page, this.rowPerPage, filters)
        .then((results) => {
          if (this.rowPerPage != results.rowPerPage) {
            this.rowPerPage = results.rowPerPage;
          }

          if (this.page != results.page) {
            this.page = results.page;
          }

          this.totalPages = results.totalPages;
          if (this.totalPages < this.page && this.totalPages != 0) {
            this.page = this.totalPages;
          }
          this.paymentsCollected = results.rows;
          this.totalRow = results.totalRow;
          this.loadingItems = false;
          this.fetchHeaders()
        });
    },
    fetchHeaders() {
      this.loadingHeaders = true;
      paymentCollectedService.fields(this.ghost).then((headers) => {
          this.headers = headers

          if(this.currentUser.system) {
            headers.unshift({ text: 'Id Transazione', value: 'transactionId', type: 'custom' })
          }

          paymentCollectedService.totalFields(this.ghost).then((headers) => {
            this.totalHeader = headers
            this.loadingHeaders = false;
          });
      });
    },
    async fetchExcel(){
      let filters = [...this.filtersValue, { type: 'custom', operator: 'custom', field: 'ghost', value: this.ghost }];
      
      let paymentCollectedToExport = await paymentCollectedService.list(undefined, 50000, filters)

      for (let i = 0; i < paymentCollectedToExport.length; i++) {
        obj[i].Data = data[i];
      }

      if(!!paymentCollectedToExport.rows)return paymentCollectedToExport.rows    
    },
    fetchMenus() {
      menuService.listForHierarchy().then((menus) => {
        this.menus = menus;
      });
    },
    async goToNew() {
      let openedCashDesk = await cashDeskService.getOpened()
      if(!openedCashDesk){
        this.$delegates.snackbar('Per creare uno storno aprire la cassa')
        return
      } 
      this.$router.push({
        name: "PaymentTransactionsCollectedNewForm",
        query: {
          pathToGoBack: this.$router.resolve({
            name: "PaymentTransactionsCollectedList",
          }).href,
        },
      })
    },
    rowClicked(row) {
      if (!!row[0]) {
        this.selected = [];
        row[0].closedAt = new Date(row[0].closedAt);
        this.rowSelected = row[0];
        this.showTransactionDetailDialog = true;
      }
    },
    applyFilters(filters) {
      this.fetchPaymentsCollected();
    },
    activeGhost(){
      operatorService.canPerformOperation(this.ghostBarcode, "Ghost").then((result) => {
        if (result) {
          this.ghost = !this.ghost      
          this.fieldsToExport = paymentCollectedService._fieldsToExport(this.ghost);
          this.applyFilters()
        } else {
          alert("Non hai i permessi per eseguire questa azione!")
        }
      }).catch(() => {
        alert("Badge non riconosciuto")
      })
      this.ghostBarcode = undefined
      this.dialogBarcode = false
    },
    toggleBarcode(){
      this.dialogBarcode = true
    },
    getCustomerFromGift(item){
      if(!!item.info.customer)
        return item.info.customer.firstname + ' ' + item.info.customer.lastname
      else
        return 'Nessun cliente'
    }
  },
  computed: {
    loading() {
      return this.loadingItems || this.loadingHeaders;
    },
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  watch: {
    page() {
      this.applyFilters();
    },
    rowPerPage() {
      this.applyFilters();
    },
    ghost(newVal){
      this.fieldsToExport = paymentCollectedService._fieldsToExport(newVal);
    },
  },
  licenseSettings: {
    viewBar: {
      bind: "viewBar"
    }
  },
  currentUser: {
    bind: 'currentUser'
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>