import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class PaymentTransactionsService {
  constructor() {

  }

  history(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/bills/history', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  update(transaction) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/transactions/paymentTransactions/update', transaction).then((response) => {
        if (response.success) {
          resolve(response.results);
        } else {
          reject("error during the call");
        }
      });
    });
  }

  updateItemOperator(param) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/transactions/paymentTransactions/updateItemOperator', param).then((response) => {
        if (response.success) {
          resolve(response.results);
        } else {
          reject("error during the call");
        }
      });
    });
  }

  delete(transaction) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/transactions/paymentTransactions/delete', transaction).then((response) => {
        if (response.success) {
          resolve(response.results);
        } else {
          reject("error during the call");
        }
      });
    });
  }

  updateServiceOperator(param) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/transactions/paymentTransactions/updateServiceOperator', param).then((response) => {
        if (response.success) {
          resolve(response.results);
        } else {
          reject("error during the call");
        }
      });
    });
  }

  lastCustomerTransactionByServiceTypes(params) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/transactions/paymentTransactions/lastCustomerTransactionByServiceTypes', params)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject("error during the call");
          }
        })
    })
  }


  fields() {
    return Promise.resolve([
      { text: 'Cliente', value: 'customer', type: 'custom' },
      { text: 'Tipo documento', value: 'documentType', type: 'enum' },
      { text: 'Contanti', value: 'cashPaid', type: 'custom' },
      { text: 'Assegno', value: 'checkPaid', type: 'custom' },
      { text: 'Pagamento elettronico', value: 'cardPaid', type: 'custom' },
      { text: 'Pagamento con tessera', value: 'customerCardPaid', type: 'custom' },
      { text: 'Altri metodi', value: 'otherPaid', type: 'custom' },
      { text: 'Importo Totale', value: 'totalValue', type: 'custom' },
      { text: 'Chiuso il', value: 'date', type: 'custom' },
      { text: 'Servizi Erogati', value: 'providedServices', type: 'custom' },
    ])
  }
}

export default new PaymentTransactionsService()